<template>
  <el-row class="mx-5">
    <el-col>
      <el-card shadow="never">
        <PageHeader 
          slot="header" 
          @onCreate="onCreate" 
        />
        <div>
          <el-dialog
            :title="theme ? 'Editar módulo de carreira' : 'Cadastrar módulo de carreira'"
            :visible.sync="registering"
            fullscreen
            center
          >
            <Register
              @saved="getThemes(filters); onCancel()"
              @cancel="onCancel()"
            />
          </el-dialog>
          <el-dialog
            :title="`Informação do módulo de carreira ${theme ? theme.description: ''}`"
            class="with-border"
            :visible.sync="showing"
            fullscreen
            center
          >
            <PreviewModule
              v-if="showing && theme"
              :module="theme"
            />
          </el-dialog>
          <FilterList v-model="search" />
        </div>

        <List @changed="getThemes(filters); onCancel()">
          <template slot-scope="{row}">
            <el-button-group>
              <el-button
                v-if="permissions.includes('update-theme')"
                size="mini"
                icon="el-icon-edit"
                :title="'Editar tema'"
                @click="onEdit(row)"
              >
                Editar
              </el-button>
              <el-button
                size="mini"
                type="info"
                icon="el-icon-view"
                @click="onShow(row)"
              >
                Visualizar
              </el-button>
              <UploadExamButton :module="row" />
              <el-button-group />
            </el-button-group>
          </template>
        </List>
        <div class="d-flex justify-content-center p-2">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="meta.total"
            :current-page.sync="meta.current_page"
          />
        </div>
      </el-card>
    </el-col>
  </el-row>
</template>
<script>

import List from "@/admin/Pages/theme/components/List"
import Register from "@/admin/Pages/theme/components/Register.vue"
import PreviewModule from "@/admin/Pages/module/components/PreviewModule.vue"
import FilterList from "@/admin/Pages/theme/components/FilterList.vue"
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {defaultLoadingConfig} from "@/admin/constants/general"
import UploadExamButton from "@/admin/components/UploadModuleExamButton"

export default{
    components: {
        List,
        Register,
        FilterList,
        PreviewModule,
        UploadExamButton
    },
    data() {
        return {
          registering: false,
          showing: false,
          search: '',
          meta : {
              current_page: 1,
              total: 10
          },
        }
    },
    computed:{
      ...mapGetters(['permissions','theme']),
      filters(){
        return `${this.search}&page=${this.meta.current_page}`
      }
    },
    watch:{
      filters: {
        handler(){
            clearTimeout(this._timerId)
            this._timerId = setTimeout(() => {
                this.$loading(defaultLoadingConfig);
            this.getThemes(this.filters)
                .then((r)=> {
                    this.meta = r.data.meta;
                }).finally(()=>this.$loading().close())
            }, 500)
        },
        immediate: true,
      },
    },
    methods:{
        ...mapActions(['getThemes','getTheme']),
        ...mapMutations(['SET_THEME', 'SET_MODULE_ID']),
        onEdit(theme){
          this.getTheme({id: theme.id}).then(()=> this.registering = true);
        },
        onCreate(){
            this.SET_THEME(null);
            this.registering = true;
        },
        onShow({ id }){
          this.SET_THEME(null);
          this.getTheme({ id }).then(() => this.showing = true);
        },
        onCancel(){
            this.registering = false;
            this.SET_THEME(null);
        },
    },
}
</script>
